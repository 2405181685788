<template>
	<div class="content-wrapper">
		<h1 class="mb-5">Backoffice</h1>
		<b-card class="mb-4">
			<b-row class="my-1">
				<b-col lg="3">
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Search"
						></b-form-input>
					</b-input-group>
				</b-col>
				<b-col lg="3">
					<b-button
						size="sm"
						class="mb-2"
						v-b-toggle.collapse-advancedFilter
						:disabled="advancedFilter_visible"
					>
						<b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
					</b-button>
				</b-col>
				<b-col class="text-lg-right">
					<b-button size="sm" class="mb-2" v-b-toggle.sidebar-backoffice>
						<b-icon icon="plus" aria-hidden="true"></b-icon> New member
					</b-button>
				</b-col>
			</b-row>
		</b-card>
		<b-collapse
			id="collapse-advancedFilter"
			v-model="advancedFilter_visible"
			class="mt-2"
		>
			<b-card class="mb-4">
				<div class="d-flex">
					<span>Advanced Filter</span>
					<b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
				</div>
				<b-row class="mt-3">
					<b-col>
						<b-form-select
							v-model="levelTypeListSelected"
							:options="computedlevelTypeList"
						></b-form-select>
					</b-col>
					<b-col>
						<b-form-select
							v-model="companyListSelected"
							:options="computedCompanyList"
						></b-form-select>
					</b-col>
					<b-col v-if="companyListSelected">
						<b-form-select
							v-model="areaSelected"
							:options="areaList"
						></b-form-select>
					</b-col>
					<b-col v-if="companyListSelected">
						<b-form-select
							v-model="departmentSelected"
							:options="departmentList"
						></b-form-select>
					</b-col>
					<b-col v-if="companyListSelected">
						<b-form-select
							v-model="clusterSelected"
							:options="clusterList"
						></b-form-select>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
			<b-card>
				<b-table
					hover
					:items="teamRolesList"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
				>
					<template #cell(loginLevel)="row">
						{{ getLevelName(row.item.loginLevel) }}
					</template>
					<template #cell(company)="row">
						<template v-if="row.item.companies.length == companyList.length"
							>All</template
						>
						<template v-else>{{ getCompanyList(row.item.companies) }}</template>
					</template>
					<template #cell(actions)="row">
						<b-dropdown
							size="sm"
							variant="link"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<b-icon-gear-fill></b-icon-gear-fill>
							</template>
							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-backoffice
								@click="
									currentItem = row.item;
									tmpItem = JSON.parse(JSON.stringify(row.item));
								"
								>Edit</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								v-if="row.item.attivo"
								@click="row.item.attivo = !row.item.attivo"
								>Block</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								@click="row.item.attivo = !row.item.attivo"
								v-else
								>Unlock</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								@click="
									currentItem = row.item;
									showDeleteModal = true;
								"
								>Delete</b-dropdown-item
							>
						</b-dropdown>
					</template>
					<template #cell(attivo)="row">
						<b-icon-unlock-fill v-if="row.item.attivo"></b-icon-unlock-fill>
						<b-icon-lock-fill v-else></b-icon-lock-fill>
					</template>
				</b-table>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="mt-4 mb-1"
				></b-pagination>
			</b-card>
		</b-overlay>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-backoffice"
			right
			shadow
			lazy
			@hidden="resetData"
		>
			<div class="content-wrapper my-3 mx-1">
				<h4 v-if="currentItem">Edit member</h4>
				<h4 v-else>New member</h4>
				<div
					class="upload-photo-cicrle"
					v-bind:style="{
						backgroundImage: 'url(' + backgroundImage() + ')',
					}"
				>
					<b-avatar v-if="file_photo_url.length == 0" size="6rem"></b-avatar>
					<b-form-file
						class="button-file photo-button"
						v-model="file_photo"
						accept="image/jpeg, image/png, image/gif"
					>
						<template #placeholder>
							<b-icon-camera-fill />
						</template>
						<template #file-name>
							<b-icon-camera-fill />
						</template>
					</b-form-file>
						<b-icon
								v-if="loadingFile"
								class="loading-file"
								icon="arrow-clockwise"
								animation="spin"
								font-scale="2"
							></b-icon>
				</div>
				<b-row class="mt-3">
					<b-col>
						<b-form-group label="Name">
							<b-form-input v-model="tmpItem.nome" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Surname">
							<b-form-input v-model="tmpItem.cognome" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Password">
							<b-form-input v-model="tmpItem.password" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-form-group label="Email">
					<b-form-input v-model="tmpItem.email" />
				</b-form-group>
				<b-form-group label="Login level">
					<b-form-select
						v-model="tmpItem.loginLevel"
						:options="levelTypeList"
					></b-form-select>
				</b-form-group>
				<b-form-group label="User Bo Padre">
					<b-form-select
						v-model="tmpItem.id_padre"
						:options="itemsOptions"
					></b-form-select>
				</b-form-group>

				<b-row class="mt-3">
					<b-col>
						<b-form-group label="Background color">
							<b-form-input type="color" v-model="tmpItem.calendar_bg_color" />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Text color">
							<b-form-input
								type="color"
								v-model="tmpItem.calendar_color"
							/> </b-form-group
					></b-col>
				</b-row>

				<b-form-group label="Company">
					<div class="checkbox-container">
						<b-form-checkbox
							v-for="option in companyList"
							v-model="tmpItem.companies"
							:key="option.value"
							:value="option.value"
						>
							{{ option.text }}
						</b-form-checkbox>
					</div>
				</b-form-group>

				<div class="mt-5 mb-5">
					<b-button
						v-if="isLoading"
						squared
						variant="primary"
						size="lg"
						class="big-button"
						disabled
						><b-spinner small type="grow"></b-spinner> Loading...</b-button
					>
					<b-button
						v-else
						squared
						variant="primary"
						size="lg"
						class="big-button"
						@click="saveItem"
						>Save</b-button
					>
				</div>
			</div>
		</b-sidebar>
		<b-modal
			v-model="showDeleteModal"
			title="Attention!"
			ok-title="Confirm"
			cancel-title="Cancel"
			@hidden="resetData"
			@ok="deleteItem(currentItem.id)"
			>Do you really want to delete the selected element?</b-modal
		>
	</div>
</template>

<script>
	import AxiosService from "./../axiosServices/AxiosService";
	export default {
		components: {},
		data() {
			return {
				items: null,
				fields: [
					{ key: "id", label: "ID" },
					{ key: "nome", label: "Name" },
					{ key: "cognome", label: "Surname" },
					{ key: "email", label: "Email" },
					{ key: "loginLevel", label: "Login level" },
					{ key: "company", label: "Company" },
					{ key: "actions", label: "Actions" },
					{ key: "attivo", label: "Active" },
				],
				currentPage: 1,
				perPage: 10,
				filter: "",
				advancedFilter_visible: false,
				currentItem: null,
				tmpItem: {
					id: 0,
					nome: "",
					cognome: "",
					password: "",
					email: "",
					telefono: "",
					avatar_url: "",
					companies: [],
					role: 1,
					id_padre: null,
					attivo: true,
					calendar_bg_color: "",
					calendar_color: "",
				},
				itemsOptions: [],
				showDeleteModal: false,

				//Advanced Filter Options
				companyList: [],
				companyListSelected: null,

				levelTypeList: [],
				levelTypeListSelected: null,

				areaList: [{ value: null, text: "All areas" }],
				areaSelected: null,

				departmentList: [{ value: null, text: "All departments" }],
				departmentSelected: null,

				clusterList: [{ value: null, text: "All clusters" }],
				clusterSelected: null,

				file_photo: null,
				loadingFile: false,
				isLoading: false,
			};
		},
		service: null,
		created() {
			this.service = new AxiosService("UserBo");
		},
		mounted() {
			this.itemsOptions.splice(0);
			this.service.read().then((data) => {
				this.items = data;
				data.forEach((element) => {
					this.itemsOptions.push({
						text: element.nome + " " + element.cognome,
						value: element.id,
					});
				});
			});
			this.service
				.getOptions("LoginLevels")
				.then((data) => (this.levelTypeList = data));
			this.service
				.getOptions("Company")
				.then((data) => (this.companyList = data));
		},
		methods: {
			getLevelName(idLevel) {
				const level = this.levelTypeList.find((x) => x.value === idLevel);
				return level ? level.text : "";
			},
			getCompanyList(entryCompanyList) {
				if (!entryCompanyList) return "";
				let companyListString = "";
				for (let i = 0; i < entryCompanyList.length; i++) {
					let currentCompany = this.companyList.find(
						(x) => x.value === entryCompanyList[i]
					);
					let currentCompanyName = currentCompany ? currentCompany.text : "";
					companyListString += currentCompanyName;
					if (currentCompanyName && i < entryCompanyList.length - 1)
						companyListString += ", ";
				}
				return companyListString;
			},
			backgroundImage() {
				return this.file_photo_url || this.tmpItem.avatar_url;
			},
			resetData() {
				this.currentItem = null;
				this.tmpItem = {
					id: 0,
					nome: "",
					cognome: "",
					password: "",
					email: "",
					telefono: "",
					avatar_url: "",
					companies: [],
					loginLevel: 0,
					role: 1,
					id_padre: null,
					attivo: true,
				};
				this.file_photo = null;
			},
			async saveItem() {
				this.isLoading = true;
				this.loadingFile = true;
				let item = { ...this.tmpItem };

				const validationObject = this.$validate("backoffice", item);
				if (!validationObject.validation) {
					this.$errorToast(validationObject.messages.join(", "));
					this.isLoading = false;
					return false;
				}

				if (this.file_photo) {

					if (this.file_photo.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return;
					}
					const formData = new FormData();
					formData.append("pic", this.file_photo);
					let imageName = await this.service
						.uploadFile(formData)
						.then((res) => res.data)
						.finally(() => {
							this.loadingFile = false;
						})
					let imageUrl = this.$driveAddress + imageName;
					item.avatar_url = imageUrl.replaceAll(" ", "%20");
				}

				if (item.id) {
					this.service
						.update(item)
						.then((response) => {
							if (response.id == item.id) {
								Object.keys(this.currentItem).forEach((element) => {
									if (Object.prototype.hasOwnProperty.call(item, element)) {
										this.currentItem[element] = item[element];
									}
								});
								this.$root.$emit("bv::toggle::collapse", "sidebar-backoffice");
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						})
						.finally(() => {
							this.isLoading = false;
						});
				} else {
					this.service
						.create(item)
						.then((response) => {
							if (response.id) {
								item.id = response.id;
								this.items.push(item);
								this.$root.$emit("bv::toggle::collapse", "sidebar-backoffice");
								this.$successToast();
							} else {
								this.$errorToast();
								return false;
							}
						})
						.catch(() => {
							this.$errorToast();
							return false;
						})
						.finally(() => {
							this.isLoading = false;
						});
				}
			},
			deleteItem(id) {
				this.service
					.delete(id)
					.then((response) => {
						if (response) {
							this.items = this.items.filter(
								(currentItem) => id !== currentItem.id
							);
							this.$successToast();
						} else {
							this.$errorToast();
							return false;
						}
					})
					.catch(() => {
						this.$errorToast();
						return false;
					});
			},
		},
		computed: {
			totalRows() {
				return this.teamRolesList ? this.teamRolesList.length : 0;
			},
			teamRolesList() {
				if (this.items == null) return [];
				return this.items.filter((item) => {
					if (
						this.levelTypeListSelected &&
						item.role != this.levelTypeListSelected
					)
						return false;
					if (
						this.companyListSelected &&
						!item.companies.includes(this.companyListSelected)
					)
						return false;

					if (this.areaSelected && !item.area.includes(this.areaSelected))
						return false;

					if (
						this.departmentSelected &&
						!item.department.includes(this.departmentSelected)
					)
						return false;

					if (
						this.clusterSelected &&
						!item.cluster.includes(this.clusterSelected)
					)
						return false;
					return true;
				});
			},
			computedlevelTypeList() {
				return [{ value: null, text: "All roles" }, ...this.levelTypeList];
			},
			computedCompanyList() {
				return [{ value: null, text: "All companies" }, ...this.companyList];
			},

			file_photo_url() {
				return this.file_photo ? URL.createObjectURL(this.file_photo) : "";
			},
		},
		watch: {
			filter: {
				handler() {
					this.currentPage = 1;
				},
			},

			companyListSelected: {
				handler() {
					if (this.companyListSelected) {
						this.service
							.readCustomEndpoint("Option/AT_Areas/" + this.companyListSelected)
							.then(
								(data) =>
									(this.areaList = [
										{ value: null, text: "All areas" },
										...data,
									])
							);
						this.service
							.readCustomEndpoint(
								"Option/AT_Department/" + this.companyListSelected
							)
							.then(
								(data) =>
									(this.departmentList = [
										{ value: null, text: "All departments" },
										...data,
									])
							);
						this.service
							.readCustomEndpoint(
								"Option/AT_Cluster/" + this.companyListSelected
							)
							.then(
								(data) =>
									(this.clusterList = [
										{ value: null, text: "All clusters" },
										...data,
									])
							);
					} else {
						console.log("** show all companies **");
					}
				},
			},
		},
	};
</script>
